import React from 'react'
import styles from "./footer.module.css";
import { Iconq01, Iconq02, Iconq03 } from '../../Svg';
import XIcon from '@mui/icons-material/X';
import { searchOrFilterProduct , setFromSearch,setFilterValues } from "../../redux/reducers/productSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
const Footer = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { filterOptions,fromSearch,pagination ,filterValues } = useSelector((state) => state.products);


  const handleProductClick = async (nameOrCategory) => {
    console.log("nameOrCategory:", nameOrCategory); 
    try {
      const resultAction = await dispatch(searchOrFilterProduct({ searchInput: nameOrCategory,isMobile: true, }));
      unwrapResult(resultAction); 
      dispatch(setFromSearch(true));
      navigate('/Category Product');
      
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Failed to fetch product:', error);
    }
  };

  return (
    <div className={styles.container}>
         <div className={styles.contant}>
         <div className={styles.section1}>
          <h2 className={styles.logo1}>  <a className={styles.logoName}>  <div className={styles.maincontainer}>
              <img src='../../assets/images/logo.png'  className={styles.mainImg} />
            </div></a></h2>
            <div className={styles.page}>
    <a className={styles.pageLink}>We’re available by phone - 9148884999 <br/><a href="mailto:info@misseymoppet.com" style={{textDecoration:"none",color:"inherit"}}>info@misseymoppet.com</a></a>
    <div className={styles.icons}> 
        <a href=''><Iconq01 /></a>
       <a href='https://www.instagram.com/missey8536/' target="_blank" rel="noopener noreferrer"><Iconq02/></a> 
        <a href='https://x.com/MisseyM28452' target="_blank" rel="noopener noreferrer"><Iconq03/> </a>
    </div>  

    </div>
         </div>
         <div className={styles.section}>
  <h2 className={styles.heading}>Useful links</h2>
  <div className={styles.pages}>
    <a href="/" className={styles.pageLink}>Home</a>
    <a href="/Contact US" className={styles.pageLink}>Contact us</a>
    <a href="/FAQ" className={styles.pageLink}>F A Q</a>
  </div>
</div>

         <div className={styles.section}>
          <h2 className={styles.heading}>Products</h2>
          <div className={styles.pages}>

    <div onClick={() => {handleProductClick("Small Baby")}} className={styles.pageLink}>Baby</div>
    <div onClick={() => {handleProductClick("Toddler")}}className={styles.pageLink}>Toddler</div>
    <div onClick={() => {handleProductClick("Kids")}} className={styles.pageLink}>Kids</div>

    </div>
         </div>
         <div className={styles.section}>
          <h2 className={styles.heading}>Policies </h2>
          <div className={styles.pages}>
    <a href="/privacypolicy" className={styles.pageLink}>Privacy Policy</a>
    {/* <a href="/termscondition" className={styles.pageLink}>Terms & Condition</a> */}
   

    </div>
         </div>
         {/* <div className={styles.section}>
          <h2 className={styles.heading}>Contact </h2>
          <div className={styles.pages}>
    <p  className={styles.pageLink}>+91-81233 11094</p>
    <p  className={styles.pageLink}>info@aradeeventures.com</p>
    <p  className={styles.pageLink}>Bangalore, Karnataka, India</p>
    <div className={styles.icons}>  <a href="https://www.facebook.com/profile.php?id=61563804877791&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
        <Iconq01 />
      </a>  <Iconq02/>  <Iconq03/> 
    </div>  

    </div>
         </div> */}
        
         </div>
         
    </div>
  )
}

export default Footer