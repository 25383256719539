import React from "react";
import styles from "./Occasional.module.css";
import { useNavigate } from 'react-router-dom'
import { unwrapResult } from "@reduxjs/toolkit";
import { searchOrFilterProduct,setFromSearch } from "../../redux/reducers/productSlice";
import { useDispatch } from "react-redux";



const Occasional = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()


  const handleProductClick = async (nameOrCategory) => {
    console.log("nameOrCategory:", nameOrCategory); 
    try {
      const resultAction = await dispatch(searchOrFilterProduct({ searchInput: nameOrCategory }));
      unwrapResult(resultAction); 
      dispatch(setFromSearch(true));
      navigate('/Category Product');
      // window.scrollTo(0,0)
    } catch (error) {
      console.error('Failed to fetch product:', error);
    }
  };
  
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.titles}>
          <h2 className={styles.heading}>Occasional Combos</h2>
          <p className={styles.text}>
            With Stylish And Diverse Clothing Options For Different Occasions
          </p>
        </div>
        <div className={styles.boxs}>
          <div className={styles.box1}>
            <div className={styles.item1}>
              
                            <span className={styles.festive}>Festive Wear</span>
                            {/* <span className={styles.product}> BOY PRODUCTS </span> */}
                            <span className={styles.shop} onClick={() => {handleProductClick("Baby-Boy")}}> Shop Items </span>

             
            </div>
          </div>
          <div className={styles.box2}>
            <div className={styles.item1}>
            <span className={styles.festive}>Birthday Party Wear</span>
                            {/* <span className={styles.product}>GIRL PRODUCTS</span> */}
                            <span className={styles.shop} onClick={() => {handleProductClick("Baby-Girl")}}> Shop Items </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Occasional;
