import React, { useEffect, useState } from "react";
import styles from "./MobileFilter.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  sortProducts,
  searchOrFilterProduct,
  setFromSearch,
  getFilterCounts,
  setFilterValues,
} from "../../redux/reducers/productSlice";
import chroma from "chroma-js";
import { Navigate, useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Arrow1 } from "../../Svg";
import toast from "../../utils/toast";

const MobileFilter = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const { filterOptions, fromSearch, pagination, filterValues } = useSelector(
    (state) => state.products
  );

  console.log("first", pagination);

  // Local state to store filters
  const [minPrice, setMinPrice] = useState(filterValues.minPrice || 0);
  const [maxPrice, setMaxPrice] = useState(filterValues.maxPrice || 100000);
  const [selectedCategory, setSelectedCategory] = useState("Category");
  const [selectedCat, setSelectedCat] = useState(
    filterValues.selectedCategory || ""
  );
  const [selectedColor, setSelectedColor] = useState(
    filterValues.selectedColor || []
  );
  const [selectedSize, setSelectedSize] = useState(
    filterValues.selectedSize || []
  );
  const [selectedRange, setSelectedRange] = useState({
    min: filterValues.minPrice || 0,
    max: filterValues.maxPrice || 10000,
  });
  const [sortOrder, setSortOrder] = useState("");

  const priceRanges = [
    { range: "₹100 - ₹499", checked: false },
    { range: "₹500 - ₹999", checked: false },
    { range: "₹1000 - ₹1999", checked: false },
    { range: "₹2000 and Up", checked: false },
  ];

  const handleColorChange = (color) => {
    setSelectedColor((prevColors) => {
      if (prevColors.includes(color)) {
        return prevColors.filter((c) => c !== color); // Deselect if already selected
      } else {
        return [...prevColors, color]; // Add new color
      }
     
    });
    // dispatch(setFromSearch(false))
  };

  const handleSizeChange = (size) => {
    setSelectedSize((prevSizes) => {
      if (prevSizes.includes(size)) {
        return prevSizes.filter((s) => s !== size); // Deselect if already selected
      } else {
        return [...prevSizes, size]; // Add new size
      }
     
    });
    dispatch(setFromSearch(false))
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const handlePriceRangeChange = (range) => {
    if (selectedRange === range.range) {
      // Deselect if the same range is selected
      setMinPrice(0);
      setMaxPrice(10000);
      setSelectedRange(null);
    } else {
      // Set new range
      const [min, max] = range.range.split(" - ").map((price) => {
        const num = price.replace(/[^0-9]/g, "");
        return parseInt(num, 10);
      });
      setMinPrice(min || null);
      setMaxPrice(max || 10000);
      setSelectedRange(range.range);
    }
  };

  const handleMinPriceChange = (e) => {
    setMinPrice(e.target.value);
    setSelectedRange(null);
  };

  const handleMaxPriceChange = (e) => {
    setMaxPrice(e.target.value);
    setSelectedRange(null);
  };

  const handleCategoryClick = (item) => {
    setSelectedCat(selectedCat === item.name ? "" : item.name);
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const filters = {
    minPrice,
    maxPrice,
    selectedCategory: selectedCat,
    selectedColor,
    selectedSize,
    // isMobile: true,
  };

  useEffect(() => {
    dispatch(searchOrFilterProduct(filterValues));
    dispatch(getFilterCounts());
  }, [dispatch]);

  const handleApplyFilters = async () => {
    try {
      // Set filter values in Redux
      dispatch(setFilterValues(filters));

      // Perform the filter action and wait for the result
      const resultAction = await dispatch(searchOrFilterProduct(filters));
      const result = unwrapResult(resultAction);

      console.log("Result Action", resultAction); // For debugging

      // Update the fromSearch state
      dispatch(setFromSearch(true));

      // Navigate to the filtered products page
      nav(`/Category Product`);

      // Apply sorting if a sort order is selected
      if (sortOrder) {
        dispatch(sortProducts(sortOrder));
      }

      // Scroll to the top of the page
      window.scrollTo(0, 0);
    } catch (error) {
      toast.error("No products found for your selection");
      console.error("Error applying filters:", error);
      // Handle the error gracefully, such as showing a notification or logging
    }
  };

  const clearCategory = (selected) => {
    switch (selected) {
      case "Category":
        setSelectedCat("");
        break;
      case "Price":
        setSelectedRange("");
        break;
      case "Color":
        setSelectedColor("");
        break;
        case "Size":
          setSelectedSize("");
          break;

    }
  };

  const handleClear = async () => {
    setMinPrice("");
    setMaxPrice("");
    setSelectedCat("");
    setSelectedColor("");
    setSelectedSize("");
    setSelectedRange(null);

    dispatch(setFilterValues({}));

    const resultAction = await searchOrFilterProduct();

    unwrapResult(resultAction);

    nav(`/Category Product`);
  };

  console.log("minPrice", minPrice);
  console.log("maxPrice", maxPrice);
  console.log("selectedColor", selectedColor);
  console.log("selectedSize", selectedSize);
  console.log("selectedCat", selectedCat);

  return (
    <div className={styles.mobileFilterContainer}>
      <div className={styles.global}>
        <div className={styles.arrow} onClick={goBack}>
          <Arrow1 />{" "}
        </div>
        <div className={styles.glob}>
          <span className={styles.productshows}>
            Filter {pagination?.totalItems} products
          </span>
          <span className={styles.clrear} onClick={() => handleClear()}>
            Clear all
          </span>
        </div>
      </div>
      <div className={styles.categories}>
        <div className={styles.categoriesSection}>
          <ul>
            <li
              onClick={() => setSelectedCategory("Category")}
              className={selectedCategory === "Category" ? styles.active : ""}
            >
              Categories
            </li>
            <li
              onClick={() => setSelectedCategory("Price")}
              className={selectedCategory === "Price" ? styles.active : ""}
            >
              Price
            </li>
            <li
              onClick={() => setSelectedCategory("Color")}
              className={selectedCategory === "Color" ? styles.active : ""}
            >
              Color
            </li>
            <li
              onClick={() => setSelectedCategory("Size")}
              className={selectedCategory === "Size" ? styles.active : ""}
            >
              Size
            </li>
          </ul>
        </div>

        <div className={styles.filterOptionsSection}>
          <div className={styles.header}>
            <span className={styles.All}>All {selectedCategory}</span>
            <button className={styles.clearButton} onClick={() => clearCategory(selectedCategory)}>Clear</button>
          </div>

          <ul className={styles.filterList}>
            {selectedCategory === "Category" &&
              filterOptions?.category?.map((item, index) => (
                <li
                  key={index}
                  className={styles.filterItem}
                  onClick={() => handleCategoryClick(item)}
                >
                  <div className={styles.stand}>
                    <label className={styles.label}>
                      <input
                        type="checkbox"
                        className={styles.customCheckbox}
                        checked={selectedCat === item.name}
                        readOnly
                      />
                      <div className={styles.mainspace}>
                        <div className={styles.mess}> {item.name}</div>{" "}
                        <div>{item?.count}</div>
                      </div>
                    </label>
                  </div>
                </li>
              ))}

            {selectedCategory === "Price" && (
              <div className={styles.priceFilterContent}>
                {priceRanges.map((priceRange, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      checked={selectedRange === priceRange.range}
                      onChange={() => handlePriceRangeChange(priceRange)}
                    />
                    {priceRange.range}
                  </li>
                ))}
              </div>
            )}

              {selectedCategory === "Color" &&
                filterOptions?.color?.map((color, index) => {
                  const colorCode = chroma.valid(color.color) ? chroma(color.color).hex() : "linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet)"
                  return (
                    <li
                      key={index}
                      className={styles.filterItem}
                   
                    >
                      <label className={styles.label}>
                        <input
                          type="checkbox"
                          className={styles.customCheckbox}
                          checked={selectedColor.includes(color.color)}
                          onChange={() => handleColorChange(color.color)}
                          readOnly
                        />
                        <span
                          className={styles.colorCircle}
                          style={{
                            background: colorCode,
                            border: ".5px solid black",
                          }}
                        ></span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            minWidth: "22vh",
                          }}
                        >
                          {color.color} <span>{color?.count}</span>
                        </div>
                      </label>
                    </li>
                  );
                })}

{selectedCategory === "Size" &&
  [...(filterOptions?.size || [])] // Default to an empty array if size is undefined
    .sort((a, b) => {
      const parseSize = (size) => {
        const isMonth = size.includes("month");
        const isYear = size.includes("year");
        const value = parseInt(size.match(/\d+/)?.[0], 10); // Extract the numeric value
        return { isMonth, isYear, value };
      };

      const sizeA = parseSize(a.size);
      const sizeB = parseSize(b.size);

      // Sort months before years
      if (sizeA.isMonth && sizeB.isYear) return -1;
      if (sizeA.isYear && sizeB.isMonth) return 1;

      // Sort within months or years by numeric value
      return sizeA.value - sizeB.value;
    })
    .map((size, index) => (
      <li key={index} className={styles.filterItem}>
        <label className={styles.label}>
          <input
            type="checkbox"
            className={styles.customCheckbox}
            checked={selectedSize.includes(size.size)} // Check if the size is selected
            onChange={() => handleSizeChange(size.size)} // Handle size change
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: "25vh",
            }}
          >
            {size.size} <span>{size.count}</span>
          </div>
        </label>
      </li>
    ))}

          </ul>
        </div>

        <div className={styles.filterFooter}>
          <button
            className={styles.cancelButton}
            onClick={() => nav(`/Category Product`)}
          >
            Cancel
          </button>
          <button className={styles.applyButton} onClick={handleApplyFilters}>
            Apply Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileFilter;
