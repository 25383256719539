import React, { useEffect, useRef, useState } from 'react'
import styles from './Authheader.module.css'

const Authheader = () => {



    return (
        <div className={styles.container}>
            <div className={styles.mainHeader}>
                <a href='/'><img src="./assets/images/logo.png" alt="" width={"130px"} /></a>
            </div>
        </div >
    )
}

export default Authheader
