import React from 'react'
import styles from "./category.module.css"
import { useNavigate } from 'react-router-dom'
import { useDispatch , useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { resetAndSetFilterValues } from '../../redux/reducers/productSlice'

const Category = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  
  const handleProductClick = async (nameOrCategory) => {
    console.log("nameOrCategory:", nameOrCategory); 
    try {

      const resultAction = await dispatch(resetAndSetFilterValues({ searchInput: nameOrCategory,page:1}));
      unwrapResult(resultAction); 
      navigate('/category product');
      window.scrollTo(0,0)
      
      
    } catch (error) {
      console.error('Failed to fetch product:', error);
    }
  };

  return (
    <div className={styles.container}>
      
    <div className={styles.section}>
    <div className={styles.box1}>
   
    <h2 className={styles.title}>Shop By <br/>
    Category</h2>
    <button className={styles.button_3} onClick={() => navigate(`/Category Product`)}>Shop Now</button>
 
    </div>
    <div className={styles.box2} onClick={() => handleProductClick("Baby")}>
 
    <h2 className={styles.heading} >Baby</h2>
  
    </div>
    <div className={styles.box3} onClick={() => handleProductClick("Toddler")}>

    <h2 className={styles.heading} >Toddler</h2>
    
    </div>
    <div className={styles.box4} onClick={() => handleProductClick("Girl")}>
    
    <h2 className={styles.heading}>Girl</h2>

    </div>
    <div className={styles.box5} onClick={() => handleProductClick("Boy")}>

    <h2 className={styles.heading}>Boy</h2>
   
    </div>
    



    </div>
  
</div>
  )
}

export default Category
