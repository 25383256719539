import React, { useEffect, useState } from "react";
import styles from "./categorypage.module.css";
import { Box } from "@mui/material";
import {
  Fouricon,
  Likewhite,
  LikeRed,
  Menuarrow,
  MenuSvg,
  PriceArrow,
  PriceArrowrevers,
  Star,
  Arrow1,
  Arrpw1,
  FilterIcon
} from "../../Svg";
import { Link,useParams } from "react-router-dom";
import { CircularProgress , Pagination } from "@mui/material";
import StarRatings from "react-star-ratings";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Skeleton from '@mui/material/Skeleton';
import Rectangle1 from "../../assets/images/Rectangle1.png";
import axios from "axios";
// import api from "../../utils/Api";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart } from "../../redux/reducers/cartSlice";
import {
  searchOrFilterProduct,
  sortProducts,
  setFromSearch,
  subscribeToOutofstockProducts,
  getFilterCounts,
  setFilterValues,
  setIsMobile,
  resetAndSetFilterValues
} from "../../redux/reducers/productSlice";
import {
  addToWishlist,
  deleteFromWishlist,
  getWishlist,
} from "../../redux/reducers/wishlistSlice";
import Popup from "../popup/Popup";
import ErrorPage from "../errorpage/ErrorPage";
import chroma from "chroma-js";
import { useNavigate } from "react-router-dom";
import toast from "../../utils/toast";
import EmptyPage from "../emptyPage";
import { animateScroll as scroll } from "react-scroll";

const priceRangesINR = [
  { range: "₹100 - ₹499", min: 100, max: 499 },
  { range: "₹500 - ₹999", min: 500, max: 999 },
  { range: "₹1000 - ₹1999", min: 1000, max: 1999 },
  { range: "₹2000 - ₹9999", min: 2000, max: 9999 },
];

const priceRangesUSD = [
  { range: "$1 - $40", min: 1, max: 40 },
  { range: "$40 - $80", min: 40, max: 80 },
  { range: "$80 - $120", min: 80, max: 120 },
  { range: "$120 - $160", min: 120, max: 160 },
];

const Categorypage = () => {
  const rate = parseFloat(localStorage.getItem("rate")) || 1;
  const currency = localStorage.getItem("currency") || "INR";

  const token = localStorage.getItem("token");

  const dispatch = useDispatch();

  const nav = useNavigate();

 
  useEffect(() => {
    dispatch(getWishlist());
    dispatch(getFilterCounts())
  }, [dispatch]);

  const {
    productData,
    filterOptions,
    pagination,
    loading,
    error,
    isMobile,
    fromSearch,
    filterLoading,
    filterValues
  } = useSelector((state) => state.products);

  const { wishlistData } = useSelector((state) => state.wishlist);

  const { cartData } = useSelector((state) => state.cart);



  console.log("pagination", pagination);

  console.log("filterValues daaata",filterValues)

  // console.log("redux products", productData);




  const handleAddToCart = (item) => {
try{
    const body = {
      productId: item?._id,
      quantity: 1,
      countInc: true,
      size: item?.variationInventory[0]?.size,
      color: item?.variationInventory[0]?.color,
    };

    dispatch(addProductToCart(body));
  }
  catch{
    console.log("error occured while adding to cart")
  }
  };


  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Default sorting");

  const handleAddToWishList = (item) => {
    const body = {
      productId: item._id,
      quantity: 1,
      countInc: true,
      size: item?.variationInventory[0]?.size,
      color: item?.variationInventory[0]?.color,
    };

    const bodyToDelete = {
      item: item,
      size: item?.variationInventory[0]?.size,
      color: item?.variationInventory[0]?.color,
    };

    if (
      wishlistData?.some((wishlistItem) => wishlistItem?.item?._id === item._id)
    ) {
      dispatch(deleteFromWishlist(bodyToDelete));
    } else {
      dispatch(addToWishlist(body));
    }
  };



const handleSortChange = (option,value) => {

  nav('/Category Product');
  
  setSelectedOption(value);

  // Dispatch the sorting action with the selected option
  dispatch(setFilterValues({sortBy:option, page:1}));
  setIsOpen(false);
  setIsSortOpen(false)
};


  const [priceFilterOpen, setPriceFilterOpen] = useState(false);
  const [searchInput, setSearchTerm] = useState("");

  const [minPriceInput, setMinPriceInput] = useState(0)
  const [maxPriceInput, setMaxpriceInput] = useState(1000)

  const [isSortOpen, setIsSortOpen] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(24);

  const calculateShowingRange = () => {
    const start = (pagination?.currentPage - 1) * itemsPerPage + 1;
    const end = Math.min(pagination?.currentPage * itemsPerPage, pagination?.totalItems);
    return { start, end };
  };

  const { start, end } = calculateShowingRange();


    const handlePageChange = (event, page) => {
      console.log('page', page)
      dispatch(setFilterValues({ page }));
  };



  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    dispatch(resetAndSetFilterValues({searchInput:e.target.value , page:1}))
  };

  const handleMinPriceChange = (e) => {
    const input = e.target.value.replace(/[^0-9.]/g, "");
    setMinPriceInput(input);
  };

  const formattedMinPrice = currency === "USD" ? `$${minPriceInput}` : `₹${minPriceInput}`;

  const handleMaxPriceChange = (e) => {
    const input = e.target.value.replace(/[^0-9.]/g, "");
    setMaxpriceInput(input);
  };

  const formattedMaxPrice = currency === "USD" ? `$${maxPriceInput}` : `₹${maxPriceInput}`;

  const handlePriceRangeChange = (range) => {
    // Parse the selected range
    let [min, max] = range.range.split(" - ").map((price) => {
      const num = price.replace(/[^0-9]/g, "");
      return parseInt(num, 10);
    });
  
   
  
    if (currency === "USD") {
      min = Math.round(min / rate);
      max = Math.round(max / rate);
    }
    setSearchTerm("")
    dispatch(setFilterValues({maxPrice: max,minPrice:min, page:1}))
  };
  
  
  const handleColorChange = (color) => {
    // Calculate the next state for selectedColor
    const updatedColors = filterValues?.selectedColor.includes(color)
      ? filterValues?.selectedColor.filter((c) => c !== color) // Deselect if already selected
      : [...filterValues?.selectedColor, color]; // Add new color
  
      setSearchTerm("")
    dispatch(setFilterValues({  selectedColor: updatedColors, page: 1 }));
  };
  
  
  // Handle Multiple Size Changes
  const handleSizeChange = (size) => {
    const updatedSizes = filterValues?.selectedSize.includes(size)
      ? filterValues?.selectedSize.filter((s) => s !== size)
      : [...filterValues?.selectedSize, size];

      setSearchTerm("")
    dispatch(setFilterValues({ selectedSize: updatedSizes, page: 1 , }));
  };
  

  const handleCategoryClick = (category) => {
    if(filterValues?.selectedCategory === category.name){
      return
    }else{
    dispatch(setFilterValues({selectedCategory:category?.name, page:1 ,searchInput:""}))
    setSearchTerm("")
    }
  };

  const handleClearAll = async() => {
    try {
      setSearchTerm("")
      await dispatch(
        resetAndSetFilterValues()
      );
      
    } catch (error) {
      console.error("clearing attributes failed")
    }
   }



  

  const handleSave = () => {
   try{
      setSearchTerm("")
      dispatch(setFilterValues({maxPrice:maxPriceInput,minPrice:minPriceInput,page:1}))
      nav('/Category Product');
    }
    catch{
      console.error("error while saving price range")
    }
  };


useEffect(() => {
  
       dispatch(searchOrFilterProduct(filterValues));
    
      scroll.scrollTo(60, {
        duration: 1000,
        smooth: true,
      });
    
  }, [filterValues,dispatch]);

  const togglePriceFilter = () => {
    setPriceFilterOpen(!priceFilterOpen);
  };

  const [categoryFilterOpen, setCategoryFilterOpen] = useState(false);

  const toggleCategoryFilter = () => {
    setCategoryFilterOpen((prev) => !prev);
  };

  const [colorFilterOpen, setColorFilterOpen] = useState(false);
  const toggleColorFilter = () => {
    setColorFilterOpen((prev) => !prev);
  };
  const [sizeFilterOpen, setSizeFilterOpen] = useState(false);

  const toggleSizeFilter = () => {
    setSizeFilterOpen((prev) => !prev);
  };


  const capitalizeFirstLetters = (str) => {
    if (!str) return ""; 
    return str
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  

  

  return (
    <>
<div className={styles.filterContainer}>
  <div className={styles.filterStyle}>
    <FilterIcon />
    <span className={styles.filterText}>Filter</span>
    <span className={styles.countStyle}>
      {/* Count of active filters including array lengths and price range */}
      {
        Object.entries(filterValues)
          .filter(([key, value]) => {
            // Exclude specific fields except for minPrice and maxPrice with conditions
            if (key === "page"  || key === "sortBy") return false;
            if (key === "minPrice" && value === 1) return false;
            if (key === "maxPrice" && value === 100000) return false;

            // Include non-empty arrays, strings, and numbers
            if (Array.isArray(value)) return value.length > 0; // For arrays
            if (typeof value === "string") return value.trim() !== ""; // For strings
            if (typeof value === "number") return value > 0; // For numbers
            return false; // Ignore other cases
          })
          .reduce((count, [key, value]) => {

            if (key === "minPrice" ) return count; // Avoid double counting 

            // Add the length of arrays or 1 for strings/numbers
            if (Array.isArray(value)) {
              return count + value.length; // Count each item in the array
            }

            return count + 1; // Count single values (strings/numbers)
          }, 0) // Initial count is 0
      }
    </span>
  </div>
  <div className={styles.appliedFilters}>
    {/* Applied Filters */}
    {Object.entries(filterValues)
      .filter(([key, value]) => {
       
        if (key === "page" || key === "sortBy") return false;

        // Show minPrice and maxPrice only if not default values
        if (key === "minPrice" && value === 1) return false;
        if (key === "maxPrice" && value === 100000) return false;

        // Include non-empty arrays, strings, and numbers
        if (Array.isArray(value)) return value.length > 0; // For arrays
        if (typeof value === "string") return value.trim() !== ""; // For strings
        if (typeof value === "number") return value > 0; // For numbers
        return false;
      })
      .map(([key, value]) => {
        if (key === "minPrice") return null; // Skip rendering minPrice directly
        if (key === "maxPrice") {
          const minPrice = filterValues.minPrice !== 1 ? `${filterValues.minPrice}` : null;
          const maxPrice = filterValues.maxPrice !== 100000 ? `${filterValues.maxPrice}` : null;
          if (minPrice || maxPrice) {
            return (
              <span key="priceRange" className={styles.filterChip}>
                {minPrice && maxPrice ? `${minPrice} - ${maxPrice}` : minPrice || maxPrice}
              </span>
            );
          }
          return null;
        }
        if (key === "searchInput") {
          return (
            <span key={key} className={styles.filterChip}>
             { filterValues?.searchInput.replace(/-/g, ' ')}
             </span>
          ) 
        }
          return (
          <span key={key} className={styles.filterChip}>
            {Array.isArray(value) ? value.join(", ") : value.toString()}
          </span>
        );
      })}
  </div>
  {Object.entries(filterValues).some(([key, value]) => {
    // Check if any filter is active
    if (key === "page" || key === "sortBy") return false;
    if (key === "minPrice" && value === 1) return false;
    if (key === "maxPrice" && value === 100000) return false;

    if (Array.isArray(value)) return value.length > 0; // For arrays
    if (typeof value === "string") return value.trim() !== ""; // For strings
    if (typeof value === "number") return value > 0; // For numbers
    return false;
  }) && (
    <div className={styles.clearAll} onClick={handleClearAll}>
      Clear All
    </div>
  )}
</div>



    <div className={styles.container}>
    
      {filterLoading ? (
  <div className={styles.section1} >
  {/* Search Section */}
  <div className={styles.searchSkeleton}>
    <Skeleton variant="text" width="30%" height={30} style={{ marginBottom: "8px" }} />
    <Skeleton variant="rectangular" width="100%" height={40} />
  </div>

  {/* Filter by Categories */}
  <div className={styles.filterSkeleton}>
    <Skeleton variant="text" width="50%" height={30} style={{ marginBottom: "8px" }} />
    {Array.from({ length: 5 }).map((_, index) => (
      <Skeleton key={index} variant="text" width="80%" height={20} style={{ marginBottom: "6px" }} />
    ))}
  </div>

  {/* Filter by Price */}
  <div className={styles.filterSkeleton}>
    <Skeleton variant="text" width="50%" height={30} style={{ marginBottom: "8px" }} />
    <Skeleton variant="rectangular" width="40%" height={30} style={{ marginBottom: "8px" }} />
    <Skeleton variant="rectangular" width="40%" height={30} />
  </div>

  {/* Filter by Color */}
  <div className={styles.filterSkeleton}>
    <Skeleton variant="text" width="50%" height={30} style={{ marginBottom: "8px" }} />
    {Array.from({ length: 4 }).map((_, index) => (
      <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
        <Skeleton variant="circular" width={20} height={20} style={{ marginRight: "8px" }} />
        <Skeleton variant="text" width="50%" height={20} />
      </div>
    ))}
  </div>

  {/* Filter by Size */}
  <div className={styles.filterSkeleton}>
    <Skeleton variant="text" width="50%" height={30} style={{ marginBottom: "8px" }} />
    {Array.from({ length: 4 }).map((_, index) => (
      <Skeleton key={index} variant="text" width="50%" height={20} style={{ marginBottom: "6px" }} />
    ))}
  </div>
</div>
      ) : (
      <div  className={styles.section1}>
        <div className={styles.search}>
          <h3 className={styles.searchTitle}>Search</h3>
          <input
            type="text"
            placeholder="Search products..."
            className={styles.searchInput}
            value={searchInput}
            onChange={handleSearchChange}
          />
        </div>
        <div className={styles.filterSection}>
          <h3 className={styles.catagoryTitle} onClick={toggleCategoryFilter}>
            Filter by Categories
            {categoryFilterOpen ? (
              <PriceArrowrevers className={styles.arrowUp} />
            ) : (
              <PriceArrow className={styles.arrowDown} />
            )}
          </h3>
          {categoryFilterOpen && (
            <ul className={styles.filterList}>
              {filterOptions?.category?.map((category, index) => (
                <li
                  key={index}
                  onClick={() => handleCategoryClick(category)}
                  className={`${styles.categoryItem} ${
                    filterValues?.selectedCategory === category?.name ? styles.active : ""
                  }`}
                >
                 {capitalizeFirstLetters(category?.name)} <span>{category?.count}</span>
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className={styles.filterSection}>
          <h3 className={styles.priceTitle} onClick={togglePriceFilter}>
            Filter by Price
            {priceFilterOpen ? (
              <PriceArrowrevers className={styles.arrowUp} />
            ) : (
              <PriceArrow className={styles.arrowDown} />
            )}
          </h3>
          <div>
            {priceFilterOpen && (
              <div className={styles.priceFilterContent}>
                  <div className={styles.priceRange}>
                    {/* Custom input for min and max price */}
                    <input
                      type="text"
                      placeholder={currency === "USD" ? `$100.00` : `₹100`}  
                      value={formattedMinPrice}
                      onChange={handleMinPriceChange}
                    />
                    <span>
                      {" "}
                      <hr className={styles.line}></hr>{" "}
                    </span>
                    <input
                      type="text"
                      placeholder={currency === "USD" ? `$750.00` : `₹750`}                      
                      value={formattedMaxPrice}
                      onChange={handleMaxPriceChange}
                    />
                  </div>
                {/* Save button */}
                <button className={styles.saveButton} onClick={handleSave}>
                  Apply
                </button>
                <ul className={styles.priceList}>
                {(currency === "USD" ? priceRangesUSD : priceRangesINR).map(
  (priceRange, index) => (
    <li key={index}>
      <input
        type="checkbox"
        checked={
          filterValues.minPrice === priceRange.min &&
          filterValues.maxPrice === priceRange.max
        }
        onChange={() => handlePriceRangeChange(priceRange, currency)} 
      />
      {priceRange.range}
    </li>
  )
)}

                </ul>
              </div>
            )}
          </div>
        </div>

        <div className={styles.filterSection}>
          <h2 className={styles.colorTitle} onClick={toggleColorFilter}>
            {" "}
            Filter by Color
            {colorFilterOpen ? (
              <PriceArrowrevers className={styles.arrowUp} /> // Replace with your actual arrow icon or component
            ) : (
              <PriceArrow className={styles.arrowDown} /> // Replace with your actual arrow icon or component
            )}
          </h2>

          {colorFilterOpen && (
  <ul className={styles.colorList}>
    {filterOptions?.color?.map((color, index) => {
      const isValidColor = chroma.valid(color.color);
      const backgroundStyle = isValidColor
        ? { backgroundColor: chroma(color.color).hex() }
        : { background: "linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet)" };

      return (
        <li
          key={index}
          className={`${styles.colorItem} ${styles.categoryItem} ${
            filterValues?.selectedColor.includes(color.color) ? styles.active : ""
          }`}
          onClick={() => handleColorChange(color.color)}
        >
          <span className={styles.frist}>
            <span
              className={styles.colorCircle}
              style={backgroundStyle}
            ></span>
            <p className={styles.text}>{capitalizeFirstLetters(color?.color)}</p>
          </span>
          <p className={styles.text55}>{color.count}</p>
        </li>
      );
    })}
  </ul>
)}


        </div>

        <div className={styles.filterSection}>
          <h2 className={styles.sizeTitle} onClick={toggleSizeFilter}>
            {" "}
            Filter by Size
            {sizeFilterOpen ? (
              <PriceArrowrevers className={styles.arrowUp} /> // Replace with your actual arrow icon or component
            ) : (
              <PriceArrow className={styles.arrowDown} /> // Replace with your actual arrow icon or component
            )}
          </h2>
          {sizeFilterOpen && (
  <ul className={styles.sizeList}>
    {[...(filterOptions?.size || [])] // Default to an empty array if size is undefined
      .sort((a, b) => {
        const parseSize = (size) => {
          const isMonth = size.includes("month");
          const isYear = size.includes("year");
          const value = parseInt(size.match(/\d+/)?.[0], 10); // Extract the numeric value 
          return { isMonth, isYear, value };
        };

        const sizeA = parseSize(a.size);
        const sizeB = parseSize(b.size);

        // Sort months before years
        if (sizeA.isMonth && sizeB.isYear) return -1;
        if (sizeA.isYear && sizeB.isMonth) return 1;

        // Sort within months or years by numeric value
        return sizeA.value - sizeB.value;
      })
      .map((size, index) => (
        <li key={index} className={styles.sizeItem}>
          <span>
            <input
              type="checkbox"
              checked={filterValues?.selectedSize.includes(size.size)}
              onChange={() => handleSizeChange(size.size)}
            />{" "}
            {size.size}
          </span>
          <span className={styles.count}>{size.count}</span>
        </li>
      ))}
  </ul>
)}



        </div>
      </div>
    )}

      {/* section2 right side product */}

      <div className={styles.section2}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: 20,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {productData?.length > 0 ? (
              <>
                <div className={styles.box2}>         
                  <div className={styles.showing}>
                    <h5 className={styles.show12}>
                      Showing {start}–{end} of {pagination?.totalItems} results
                    </h5>
                  </div>
                  <div className={styles.showing}>
                  
                    <div className={styles.dropdown}>
  <button
    className={styles.dropdownbutton}
    onClick={() => setIsOpen(!isOpen)}
  >
    <div className={styles.gaparrow}>
      {selectedOption} <Arrpw1 />
    </div>
  </button>
  {isOpen && (
    <div className={styles.dropdown_content}>
      <p className={styles.dropdown_header}>SORT BY</p>
      <label className={styles.dropdown_option}>
        <input
          type="radio"
          name="sort"
          value="Default Sorting"
          onChange={() => handleSortChange("Default Sorting", "Default Sorting")}
          checked={selectedOption === "Default Sorting"}
        />
        Default Sorting
      </label>
      <label className={styles.dropdown_option}>
        <input
          type="radio"
          name="sort"
          value="newest"
          onChange={() => handleSortChange("newest","What's New")}
          checked={selectedOption === "What's New"}
        />
        What's New
      </label>
      <label className={styles.dropdown_option}>
        <input
          type="radio"
          name="sort"
          value="price-high-to-low"
          onChange={() => handleSortChange("priceHighToLow","Price - high to low")}
          checked={selectedOption === "Price - high to low"}
        />
        Price - high to low
      </label>
      <label className={styles.dropdown_option}>
        <input
          type="radio"
          name="sort"
          value="price-low-to-high"
          onChange={() => handleSortChange("priceLowToHigh","Price - low to high")}
          checked={selectedOption === "Price - low to high"}
        />
        Price - low to high
      </label>
      <label className={styles.dropdown_option}>
        <input
          type="radio"
          name="sort"
          value="ratings-high-to-low"
          onChange={() => handleSortChange("RatingsHighToLow","Ratings - high to low")}
          checked={selectedOption === "Ratings - high to low"}
        />
        Ratings - high to low
      </label>
    </div>
  )}
</div>

                  </div>
                </div>
                <div className={styles.productcount}>
                  <span className={styles.show124}>
                  Showing {start}–{end} of {pagination?.totalItems} results </span>
                  </div>
                <div className={styles.bag}>
                  {productData?.map((item, index) => {
                    // Adjust prices if currency is USD
                    const displaySalePrice =
                      currency === "USD"
                        ? item.salePrice * rate
                        : item.salePrice;
                    const displayBasePrice =
                      currency === "USD"
                        ? item.basePrice * rate
                        : item.basePrice;
                    const currencySymbol = currency === "USD" ? "$" : "₹";

                    return (
                      <Link
                        to={`/Category Product/Product details/${item._id}`}
                        style={{ textDecoration: "none" }}
                        key={index}
                        
                        onClick={() =>{
                         
                           window.scrollTo(0, 0)
                          
                          }
                      }
                      >
                        <div className={styles.box1} key={index}>
                          <div className={styles.maincontainer}>
                            
                            <img
                              loading="lazy" 
                              src={item?.featuredImage}
                              className={styles.mainImg_1}
                              style={{ display: loading ? 'none' : 'block' }}
                            />
                            <div
                              style={{ position: "absolute", top: 1, left: -4 }}
                            >
                              <p className={styles.sale}>SALE</p>
                            </div>
                          </div>
                          <div className={styles.content}>
                            <div className={styles.left}>
                              <h4 className={styles.name}>{item?.name?.toLowerCase().replace(/\b\w/g, char => char.toUpperCase())}</h4>
                              <div className={styles.stars}>
                                <StarRatings
                                  rating={item?.averageRating}
                                  starRatedColor="gold"
                                  numberOfStars={5}
                                  starDimension="15px"
                                  starSpacing="1px"
                                  name="rating"
                                />
                              </div>
                              {item?.stockQuantity > 0 ? (
                              <div className={styles.price}>
                                <h5 className={styles.discoutprice}>
                                  {currencySymbol}
                                  {displaySalePrice.toFixed(2)}
                                </h5>
                                <h5 className={styles.beforeprice}>
                                  {currencySymbol}
                                  {displayBasePrice.toFixed(2)}
                                </h5>
                              </div>
                              ) : (
                                <div
                                
                                style={{ color: "red", marginBottom:'8%',fontSize:'14px' }}
                              >
                                Out of stock
                             
                              </div>
                              )}
                            </div>
                            <div
                              className={styles.right}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                                
                              }}
                            >
                              <div
                                className={styles.Like}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  e.preventDefault();
                                  if (token) {
                                    handleAddToWishList(item);
                                  } else {
                                    nav("/login");
                                    toast.warning("Please login");
                                  }
                                }}
                              >
                                {wishlistData.some(
                                  (wishlistItem) =>
                                    wishlistItem?.item?._id === item._id
                                ) ? (
                                  <FavoriteIcon
                                    style={{
                                      width: "25px",
                                      color: "red",
                                      border: "none",
                                    }}
                                  />
                                ) : (
                                  <FavoriteBorderIcon
                                    sx={{
                                      stroke: "#ffffff",
                                      strokeWidth: 1,
                                      width: "25px",
                                      color: "black",
                                    }}
                                  />
                                )}
                              </div>
                              {item?.stockQuantity < 1 ? (
                                <>
                              
                                <div  className={styles.AddCart}  style={{marginBottom:"-10px"}} onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  dispatch(subscribeToOutofstockProducts({productId:item?._id}))
                                }
                                }>+ Notify me</div>
                                </>
                              ) : (
                                <>
                                  {cartData?.some(
                                    (cartItem) =>
                                      cartItem?.item?._id === item._id
                                  ) ? (
                                    <button
                                      className={styles.AddCart}
                                      disabled
                                      style={{
                                        background: "none",
                                        border: "none",
                                        cursor: "not-allowed",
                                        color: "green",
                                      }}
                                    >
                                      Added to Cart
                                    </button>
                                  ) : (
                                    <>
                                      <Link to="/mycart">
                                        <button
                                          className={styles.AddCart}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation()
                                            if (token) {
                                              handleAddToCart(item);
                                            } else {
                                              nav("/login");
                                              toast.warning("Please Login");
                                            }
                                          }}
                                          style={{
                                            background: "none",
                                            border: "none",
                                            cursor: "pointer",
                                          }}
                                        >
                                          + Add to Cart 
                                        </button>
                                      </Link>
                                      {item?.stockQuantity <= 5 ? (
                                        <div
                                          style={{
                                            color: "orange",
                                            fontWeight: 400,
                                            fontSize: 12,
                                            textDecoration: "none",
                                          }}
                                        >
                                          Only {item?.stockQuantity} left in
                                          stock
                                        </div>
                                      ) : null}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
                <div className={styles.pagination}>
             
                <Pagination
                count={pagination?.totalPages}
                page={pagination?.currentPage}
                onChange={handlePageChange}
                shape="circular"
                 variant="outlined"
                siblingCount={1} // Show one sibling page (previous and next)
                boundaryCount={0} // Do not show first and last buttons
                sx={{
                    "& .Mui-selected": {
                        color: "#fff",
                        backgroundColor: "#C86550  !important", // custom color for selected page
                    },
                    "& .MuiPaginationItem-root:hover": {
                        color: "#fff",
                        // backgroundColor: "#1D83F8", // custom hover color
                    },
                }}
            />
           
                </div>
        
              </>
            ) : (
              <>
                <EmptyPage button={"REFRESH"} />
              </>
            )}
          </>
        )}

<div className={styles.mobilefilter}>
      {/* Sort Button */}
      <button className={styles.Sort} onClick={() => setIsSortOpen(true)}>
        Sort
      </button>

      {/* Filter Button */}
      <button
        className={styles.Filter}
        onClick={() => {
          nav("/mobile-filter")
          window.scrollTo(0, 0);
        }}
      >
        Filter
      </button>

      {/* Sort Popup */}
      {isSortOpen && (
        <div
          className={styles.modalOverlay}
          onClick={() => setIsSortOpen(false)} // Close on outside click
        >
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()} // Prevent closing on inner click
          >
            <h4 className={styles.sortheading}>SORT BY</h4>
            <div className={styles.sortOption}>
            <input
          type="radio"
          name="sort"
          value="Default Sorting"
          onChange={() => handleSortChange("Default Sorting", "Default Sorting")}
          checked={selectedOption === "Default Sorting"}
        />
        Default Sorting
            </div>
            <div className={styles.sortOption}>
            <input
          type="radio"
          name="sort"
          value="newest"
          onChange={() => handleSortChange("newest","What's New")}
          checked={selectedOption === "What's New"}
        />
        What's New
            </div>
            <div className={styles.sortOption}>
              <label>
              <input
          type="radio"
          name="sort"
          value="price-high-to-low"
          onChange={() => handleSortChange("priceHighToLow","Price - high to low")}
          checked={selectedOption === "Price - high to low"}
        />
        Price - high to low
              </label>
            </div>
            <div className={styles.sortOption}>
              <label>
              <input
          type="radio"
          name="sort"
          value="price-low-to-high"
          onChange={() => handleSortChange("priceLowToHigh","Price - low to high")}
          checked={selectedOption === "Price - low to high"}
        />
        Price - low to high
              </label>
            </div>
            <div className={styles.sortOption}>
              <label>
              <input
          type="radio"
          name="sort"
          value="ratings-high-to-low"
          onChange={() => handleSortChange("RatingsHighToLow","Ratings - high to low")}
          checked={selectedOption === "Ratings - high to low"}
        />
        Ratings - high to low
              </label>
            </div>
            {/* Close Button */}
        
          </div>
        </div>
      )}
    </div>
      </div>
    </div>
    </>
  );
};

export default Categorypage;
